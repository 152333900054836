<template>
  <v-card class="fill-height" fluid style="min-height: 434px, margin: 20px">
    <v-card-title>Изображения на сервере</v-card-title>
    <v-card-text>
      <confirm-window />
      <v-row>
        <v-col v-for="image in images" :key="image.fileName" cols="3">
          <v-card>
            <v-img
              :src="path + image.fileName"
              height="128"
              class="grey darken-4"
            ></v-img>
            <v-card-title v-if="title" class="title">{{
              image.subTitle
            }}</v-card-title>
            <v-card-actions
              ><v-btn
                v-if="select"
                color="orange"
                text
                @click.prevent="selectImage(image)"
              >
                Выбрать
              </v-btn>

              <v-btn
                v-if="del"
                color="red"
                text
                @click.prevent="deleteImage(image)"
              >
                Удалить
              </v-btn></v-card-actions
            >
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  CONFIRM_ACTION,
  GLOBAL_EVENT,
  ITEM_TYPE
} from '../../utils/mainConsts';

import { mapMutations } from 'vuex';

import ConfirmWindow from '../ConfirmWindow';

export default {
  name: 'ImageGallery',
  props: ['select', 'del', 'title'],
  components: {
    'confirm-window': ConfirmWindow
  },
  data: () => ({
    images: []
  }),
  computed: {
    path: () => {
      return 'http://localhost:3000/images/preview/';
    }
  },
  async mounted() {
    this.update();
    this.$bus.$on('updateGallary', this.update);

    // получено событие от подтверждающего окна на удаление элемента
    this.$bus.$on(GLOBAL_EVENT.confirmDelete, async ({ item, itemType }) => {
      if (itemType !== ITEM_TYPE.file) {
        // Событие пришло не для этого листа
        return;
      }
      try {
        await this.$store.dispatch('fileDelete', {
          name: 'image',
          item: item
        });
        this.update();
        this.setMessageData({
          message: `Данные ${item.fileName} успешно удалено`,
          type: 'snackbar'
        });
      } catch (e) {
        console.log(e);
        this.setMessageData({
          message: 'При удалении произошла ошибка',
          type: 'snackbar'
        });
      }
    });
  },
  methods: {
    ...mapMutations(['setMessageData']),
    async update() {
      this.images = await this.$store.dispatch('fileFetchAll', {
        name: 'image'
      });
    },
    selectImage(image) {
      this.$emit('selected', image);
    },
    deleteImage(image) {
      this.$bus.$emit(GLOBAL_EVENT.openConfirmWindow, {
        item: image,
        itemType: ITEM_TYPE.file,
        message: `Действительно хотите удалить ${image.fileName}?`,
        action: CONFIRM_ACTION.delete
      });
    }
  }
};
</script>

<style></style>
