<template>
  <v-card style="margin: 20px">
    <v-card-actions>
      <v-file-input
        v-model="selectedFile"
        :rules="rules"
        accept="image/png, image/jpeg, image/jpg"
        placeholder="Выберите файл для загрузки"
        prepend-icon="mdi-camera"
        label="Картинка"
      ></v-file-input>
      <v-btn @click.prevent="uploadFile">Загрузить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ImageFileSelector',
  data: () => ({
    selectedFile: [],
    rules: [
      value =>
        !value || value.size < 2000000 || 'Image size should be less than 2 MB!'
    ]
  }),
  methods: {
    uploadFile() {
      const formData = new FormData();
      // Подготовка данных для отправки. Должны называться именно images тк multer ждет images
      formData.append('images', this.selectedFile);
      formData.append('author', 'DIMA');
      this.$store.dispatch('uploadImage', {
        data: formData,
        cb: this.uploadFinished
      });
    },

    uploadFinished() {
      this.$bus.$emit('updateGallary');
    }
  }
};
</script>
