<template>
  <div>
    <hf-image-gallery del="true" title="true" />
    <hf-image-file-selector />
  </div>
</template>

<script>
import ImageFileSelector from '../components/ui/ImageFileSelector';
import ImageGallery from '../components/ui/ImageGallery';

export default {
  components: {
    'hf-image-file-selector': ImageFileSelector,
    'hf-image-gallery': ImageGallery
  }
};
</script>

<style></style>
